import IconTopDoctors from 'atoms/SvgIcons/HompagePatientIcons/IconTopDoctors.svg';
import IconAcne from 'atoms/SvgIcons/HompagePatientIcons/IconAcne.svg';
import IconCosmeticTreatment from 'atoms/SvgIcons/HompagePatientIcons/IconCosmeticTreatment.svg';
import IconDiabetes from 'atoms/SvgIcons/HompagePatientIcons/IconDiabetes.svg';
import IconDigestiveMetabolic from 'atoms/SvgIcons/HompagePatientIcons/IconDigestiveMetabolic.svg';
import IconEarNoseThroar from 'atoms/SvgIcons/HompagePatientIcons/IconEarNoseThroar.svg';
import IconGynaecology from 'atoms/SvgIcons/HompagePatientIcons/IconGynaecology.svg';
import IconHeartProblems from 'atoms/SvgIcons/HompagePatientIcons/IconHeartProblems.svg';
import IconLiverProblems from 'atoms/SvgIcons/HompagePatientIcons/IconLiverProblems.svg';
import IconMenSexualWellness from 'atoms/SvgIcons/HompagePatientIcons/IconMenSexualWellness.svg';
import IconMigrain from 'atoms/SvgIcons/HompagePatientIcons/IconMigrain.svg';
import IconNeurologicalDisorders from 'atoms/SvgIcons/HompagePatientIcons/IconNeurologicalDisorders.svg';
import IconObesity from 'atoms/SvgIcons/HompagePatientIcons/IconObesity.svg';
import IconPaediatrics from 'atoms/SvgIcons/HompagePatientIcons/IconPaediatrics.svg';
import IconPain from 'atoms/SvgIcons/HompagePatientIcons/IconPain.svg';
import IconPiles from 'atoms/SvgIcons/HompagePatientIcons/IconPiles.svg';
import IconPsychologicalIssues from 'atoms/SvgIcons/HompagePatientIcons/IconPsychologicalIssues.svg';
import IconRespiratoryDiseases from 'atoms/SvgIcons/HompagePatientIcons/IconRespiratoryDiseases.svg';
import IconThyroid from 'atoms/SvgIcons/HompagePatientIcons/IconThyroid.svg';
import IconTumoursCancers from 'atoms/SvgIcons/HompagePatientIcons/IconTumoursCancers.svg';
import IconUrinaryProblems from 'atoms/SvgIcons/HompagePatientIcons/IconUrinaryProblems.svg';

import IconActiveTopDoctors from 'atoms/SvgIcons/HompagePatientIcons/IconActiveTopDoctors.svg';
import IconActiveAcne from 'atoms/SvgIcons/HompagePatientIcons/IconActiveAcne.svg';
import IconActiveCosmeticTreatment from 'atoms/SvgIcons/HompagePatientIcons/IconActiveCosmeticTreatment.svg';
import IconActiveDiabetes from 'atoms/SvgIcons/HompagePatientIcons/IconActiveDiabetes.svg';
import IconActiveDigestiveMetabolic from 'atoms/SvgIcons/HompagePatientIcons/IconActiveDigestiveMetabolic.svg';
import IconActiveEarNoseThroar from 'atoms/SvgIcons/HompagePatientIcons/IconActiveEarNoseThroar.svg';
import IconActiveGynaecology from 'atoms/SvgIcons/HompagePatientIcons/IconActiveGynaecology.svg';
import IconActiveHeartProblems from 'atoms/SvgIcons/HompagePatientIcons/IconActiveHeartProblems.svg';
import IconActiveLiverProblems from 'atoms/SvgIcons/HompagePatientIcons/IconActiveLiverProblems.svg';
import IconActiveMenSexualWellness from 'atoms/SvgIcons/HompagePatientIcons/IconActiveMenSexualWellness.svg';
import IconActiveMigrain from 'atoms/SvgIcons/HompagePatientIcons/IconActiveMigrain.svg';
import IconActiveNeurologicalDisorders from 'atoms/SvgIcons/HompagePatientIcons/IconActiveNeurologicalDisorders.svg';
import IconActiveObesity from 'atoms/SvgIcons/HompagePatientIcons/IconActiveObesity.svg';
import IconActivePaediatrics from 'atoms/SvgIcons/HompagePatientIcons/IconActivePaediatrics.svg';
import IconActivePain from 'atoms/SvgIcons/HompagePatientIcons/IconActivePain.svg';
import IconActivePiles from 'atoms/SvgIcons/HompagePatientIcons/IconActivePiles.svg';
import IconActivePsychologicalIssues from 'atoms/SvgIcons/HompagePatientIcons/IconActivePsychologicalIssues.svg';
import IconActiveRespiratoryDiseases from 'atoms/SvgIcons/HompagePatientIcons/IconActiveRespiratoryDiseases.svg';
import IconActiveThyroid from 'atoms/SvgIcons/HompagePatientIcons/IconActiveThyroid.svg';
import IconActiveTumoursCancers from 'atoms/SvgIcons/HompagePatientIcons/IconActiveTumoursCancers.svg';
import IconActiveUrinaryProblems from 'atoms/SvgIcons/HompagePatientIcons/IconActiveUrinaryProblems.svg';   

import IconAcidity from '../../public/images/HealthConcernIcons/IconAcidity.svg'
import IconObesityCommon from '../../public/images/HealthConcernIcons/IconObesityCommon.svg'
import IconUrticaria from '../../public/images/HealthConcernIcons/IconUrticaria.svg'
import IconPcod from '../../public/images/HealthConcernIcons/IconPcod.svg'
import IconCoughandCold from '../../public/images/HealthConcernIcons/IconCoughandCold.svg'
import IconMetrorrhagia from '../../public/images/HealthConcernIcons/IconMetrorrhagia.svg'
import IconEczema from '../../public/images/HealthConcernIcons/IconEczema.svg'
import IconPricklyHeat from '../../public/images/HealthConcernIcons/IconPricklyHeat.svg'
import IconUti from '../../public/images/HealthConcernIcons/IconUti.svg'






export const TOP_ICON_HOMEPAGE_BREAKPOINTS = {
    0: {
        slidesPerView: 3.2,
        slidesPerGroup: 1,
    },
    390: {
        slidesPerView: 4.1,
        slidesPerGroup: 2,
    },
    768: {
        slidesPerView: 6.5,
        slidesPerGroup: 3,
    },
    1024: {
        slidesPerView: 8,
        slidesPerGroup: 3,
    },
    1280:{
        slidesPerView: 11,
        slidesPerGroup: 3, 
    }
}

export const TREATMENT_SLIDER_HOMEPAGE_BREAKPOINTS = {
    0: {
        slidesPerView: 1.7,
        slidesPerGroup: 2,
    },
    390: {
        slidesPerView: 2.3,
        slidesPerGroup: 1.5,
    },
    590: {
        slidesPerView: 3.8,
        slidesPerGroup: 2,
    },
    768: {
        slidesPerView: 5.1,
        slidesPerGroup: 4,
    },
    1024: {
        slidesPerView: 6.5,
        slidesPerGroup: 1,
    },
    1280:{
        slidesPerView: 7,
        slidesPerGroup: 3, 
    }
}


export const TOP_HEADER_ICONS = [
    { name: 'Top Doctors', component: IconTopDoctors, activeComponent: IconActiveTopDoctors, specializationId: 1, specialization: '' },
    { name: 'Joint, Spine & Muscle', component: IconPain, activeComponent: IconActivePain, specializationId: 38, specialization: 'Joint, Spine & Muscle' },
    { name: 'Gynaecology', component: IconGynaecology, activeComponent: IconActiveGynaecology, specializationId: 39, specialization: 'Gynaecology' },
    { name: 'Diabetes', component: IconDiabetes, activeComponent: IconActiveDiabetes, specializationId: 40, specialization: 'Diabetes' },
    { name: 'Obesity', component: IconObesity, activeComponent: IconActiveObesity, specializationId: 41, specialization: 'Obesity' },
    { name: 'Thyroid', component: IconThyroid, activeComponent: IconActiveThyroid, specializationId: 42, specialization: 'Thyroid' },
    { name: 'Migrain', component: IconMigrain, activeComponent: IconActiveMigrain, specializationId: 43, specialization: 'Migraine' },
    { name: 'Skin Diseases', component: IconAcne, activeComponent: IconActiveAcne, specializationId: 44, specialization: 'Skin Diseases' },
    { name: 'Neurological Disorders', component: IconNeurologicalDisorders, activeComponent: IconActiveNeurologicalDisorders, specializationId: 46, specialization: 'Neurological Disorders' },
    { name: 'Digestive & Metabolic', component: IconDigestiveMetabolic, activeComponent: IconActiveDigestiveMetabolic, specializationId: 45, specialization: 'Digestive & Metabolic' },
    { name: 'Piles, Fissure & Fistula', component: IconPiles, activeComponent: IconActivePiles, specializationId: 47, specialization: 'Piles, Fissure & Fistula' },
    { name: 'Cosmetic Treatment', component: IconCosmeticTreatment, activeComponent: IconActiveCosmeticTreatment, specializationId: 48, specialization: 'Cosmetic Treatment' },
    { name: 'Urinary Problems', component: IconUrinaryProblems, activeComponent: IconActiveUrinaryProblems, specializationId: 49, specialization: 'Urinary Problems' },
    { name: 'Liver Problems', component: IconLiverProblems, activeComponent: IconActiveLiverProblems, specializationId: 50, specialization: 'Liver Problems' },
    { name: 'Men Sexual Wellness', component: IconMenSexualWellness, activeComponent: IconActiveMenSexualWellness, specializationId: 51, specialization: 'Men Sexual Wellness' },
    { name: 'Respiratory Diseases', component: IconRespiratoryDiseases, activeComponent: IconActiveRespiratoryDiseases, specializationId: 52, specialization: 'Respiratory Diseases' },
    { name: 'Tumours & Cancers', component: IconTumoursCancers, activeComponent: IconActiveTumoursCancers, specializationId: 53, specialization: 'Tumours & Cancers' },
    { name: 'Paediatrics', component: IconPaediatrics, activeComponent: IconActivePaediatrics, specializationId: 54, specialization: 'Paediatrics' },
    { name: 'Psychological Issues', component: IconPsychologicalIssues, activeComponent: IconActivePsychologicalIssues, specializationId: 55, specialization: 'Psychological Issues' },
    { name: 'Heart Problems', component: IconHeartProblems, activeComponent: IconActiveHeartProblems, specializationId: 56, specialization: 'Heart Problems' },
    { name: 'Eyes & ENT', component: IconEarNoseThroar, activeComponent: IconActiveEarNoseThroar, specializationId: 57, specialization: 'Eyes & ENT' }
];

export const COMMON_HEALTH_CONCERNS = [
    { name: 'UTI', component: IconUti, specializationId: 1, specialization: '' },
    { name: 'Prickly Heat', component: IconPricklyHeat, specializationId: 38, specialization: 'Joint, Spine & Muscle disorders' },
    { name: 'Eczema', component: IconEczema, specializationId: 39, specialization: 'Gynaecology' },
    { name: 'Acidity', component: IconAcidity, specializationId: 40, specialization: 'Diabetes' },
    { name: 'Metrorrhagia', component: IconMetrorrhagia, specializationId: 41, specialization: 'Obesity' },
    { name: 'Cough & Cold', component: IconCoughandCold, specializationId: 42, specialization: 'Thyroid' },
    { name: 'PCOD', component: IconPcod, specializationId: 43, specialization: 'Migraine' },
    { name: 'Obesity', component: IconObesityCommon, specializationId: 44, specialization: 'Skin Diseases' },
    { name: 'Urticaria', component: IconUrticaria, specializationId: 46, specialization: 'Neurological Disorders' },
];